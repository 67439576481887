const customerTestimonials = {
    customer_testimonials: [{
            id: 1,
            text: "Great customer service - even though I was all over the place, they were patient with me.",
            by: "Crystal, Detroit",
            location: "DETROIT",
        },
        {
            id: 2,
            text: "First time rec patient, went for curbside. WONDERFUL experience! Free Crunch-berries pre-rolls, Liberty brand lighter, and sticker with my large purchase! Budtender was very nice. WILL shop again. Thank you!",
            by: "Destiny, Ann Arbor",
            location: "ANN ARBOR",
        },
        {
            id: 3,
            text: "Nice clean facility with great staff. They are all super helpful and friendly. I like that they are open on Sundays. The have a good selection of products too.",
            by: "Woody, Aliquippa",
            location: "ALIQUIPPA",
        },
        {
            id: 4,
            text: "Great customer service. Their curbside pickup during this pandemic is 2nd to none.",
            by: "Michael, Philadelphia",
            location: "PHILADELPHIA",
        },
        {
            id: 5,
            text: "Quality products at a fair price. Staff is caring and knowledgeable. They know how to treat a client. Definitely the best in the area.",
            by: "Anthony, Bensalem",
            location: "BENSALEM",
        },
        {
            id: 6,
            text: "Best rewards system I've seen. They get good flower a decent amount of the time.",
            by: "Turner, Norristown",
            location: "NORRISTOWN",
        },
        {
            id: 7,
            text: "I love the way this shop makes you feel when you step through those doors. You are first greeted by an extremely nice receptionist and then you are able to go into the bud-room....",
            by: "Jacob, Beach Center",
            location: "BEACH CENTER",
        },
        {
            id: 8,
            text: "Call me biased, but God I love this place I haven't found any other place that has better deals than this one.",
            by: "Joseph, Rockville",
            location: "ROCKVILLE",
        },
        {
            id: 9,
            text: "Great and friendly service, good selection of items overall. Clean and quiet space to discuss products, security is friendly and helpful. I most certainly will be back.",
            by: "H, Easthampton",
            location: "EASTHAMPTON",
        },
        {
            id: 10,
            text: "The best selection anywhere around here. That keeps me coming back.",
            by: "Scott, Somerville",
            location: "SOMERVILLE",
        },
        {
            id: 11,
            text: "Call me biased, but God I love this place I haven't found any other place that has better deals than this one.",
            by: "Joseph, Rockville",
            location: "Maggie's - Baltimore",
        },
    ],
}

module.exports = customerTestimonials;
