const aboutStrings =
	{
		title: "About",
		link: "/about/",
		description: "Life. Love. Liberty. We’re driven by our passion for cannabis and our commitment to the values that keep us growing in the right direction.",
		about_title: "About",
		aboutpage_banner_title: "Life. Love. Liberty.",
		aboutpage_banner_subtitle: "We're driven by our passion for cannabis and our commitment to the values that keep us growing in the right direction.",
		aboutShopSectionTitle: "It's just better with cannabis",
		aboutShopSectionDescription: "From Blueberry Skunk to Candy Apple, we want you to explore every wonderous strain nature has given us. That's why our cannabis is hand-picked to give you your desired effect.",
		aboutShopLinkTitle: "Shop Liberty Cannabis",
		aboutShopLinkUrl: "/shop/",
		aboutRewardsSectionTitle: "Liberty Rewards",
		aboutRewardsSectionDescription: "Whether you're a new or an existing Liberty lover, Liberty is better because of patients like you. Life is better with $1 eighths-among other benefits of Liberty Rewards.",
		aboutRewardsLinkTitle: "Get Liberty Rewards",
		aboutRewardsLinkUrl: "/rewards/",
		aboutGrowSectionTitle: "How we grow responsibly",
		aboutGrowSectionDescription: "When nature gives you cannabis, you roll with it. At Liberty, we believe that cannabis can cultivate better health, prosperity, and communities. For us, responsible growth starts with integrity and diversity.",
		aboutGrowSectionDescription1: "When you choose Liberty, you're supporting a company nurtured by a responsible and progressive leader in cannabis:",
		aboutGrowSectionDescriptionCompany: "Holistic Industries",
		aboutGrowSectionDescriptionLink: "https://www.holisticindustries.com",
		aboutGrowLinkText: "Grow with us",
		aboutGrowLinkURL: "https://careers.jobscore.com/careers/holisticindustries/",
		aboutGrowSectionDescription2: "So you can feel good, not just because our cannabis brands free your mind but because they're also putting in the work.",
		aboutCaresSectionTitle: "Liberty Cannabis Cares",
		aboutCaresSectionDescription: "Liberty Cannabis Cares is the corporate social responsibility promise of Holistic Industries. From Day 1, we were on a mission-inspired by the stories and struggles of individuals, families, and communities across the country. In every state we operate in, in every neighborhood we're lucky to be a part of, we think beyond the business of cannabis and work toward positive social change.",
		aboutCaresLinkTitle: "Learn more about our impact",
		aboutCaresLinkUrl: "https://www.holisticindustries.com/impact",
		aboutBudSectionTitle: "We're here to be your bud",
		aboutBudSectionDescription: "How do you take your cannabis? We're here to help. But take your time. There's no rush. Life's about the journey, not the destination. Individualized conversations and exceptional experiences. It's the Liberty way.",
		aboutBudSectionLocationTitle: "Our locations",
		aboutBudSectionLocationDescription: "We keep growing! Choose your go-to Liberty location from the menu below.",
		aboutBudSectionLocationDescription2: "Your current location isn’t set. Enter your ZIP code to find the dispensary closest to you",
		aboutStoreSelectionOption: "Select a dispensary",
		aboutStoreSelectionButtonTitle: "Make this my store",
		aboutStoreSelectionViewSectionTitle: "Your current selected location:",
		aboutStoreSelectionViewTitle: "Liberty Cannabis",
		aboutStoreSelectionViewHoursTitle: "Store hours:",
		aboutUserNotSelectedLocation: "The users location has not been established",
		aboutModalTitle: "Heads up...",
		aboutModalSubTitle: "You're about to change your dispensary location.",
		aboutModalCancelButtonText: "Cancel",
		aboutModalContinueButtonText: "Continue",
		aboutImg1: "Isolated Bud",
		aboutImg2: "Strane",
		aboutImg3: "Do Drops",
		aboutImg4: "Garcia Handpicked",
		aboutImg5: "PAX",
		aboutImg6: "Liberty Cannabis Staff",
		aboutImg7: "Liberty Cannabis Staff",
		aboutImg8: "Liberty Rewards and Isolated Bud",
		aboutImg9: "Liberty Rewards and Isolated Bud",
		aboutImg10: "",
		aboutImg11: "",
	}

module.exports = aboutStrings