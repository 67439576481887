const employeeTestimonials = {
    employee_testimonials: [{
            id: 1,
            text: "We are Philadelphia's friendliest dispensary. Our patient interactions are second to none. Kind buds and kind people!",
            by: "Sam, Liberty Philadelphia",
            location: "PHILADELPHIA",
        },
        {
            id: 2,
            text: "The Bensalem team uses our in-depth product knowledge and passion for helping others to guide patients through the world of medicinal cannabis.",
            by: "Jason, Liberty Bensalem",
            location: "BENSALEM",
        },
        {
            id: 3,
            text: "We're the friendly neighborhood cannabis store. Everyone here is excited to assist and educate our community about the wide world of cannabis.",
            by: "Austin, Liberty Ann Arbor",
            location: "ANN ARBOR",
        },
        {
            id: 4,
            text: "We place community over commodity. Our knowledgeable Wellness Guides are happy to help patients find the ideal cannabis products—we are here for their wellness.",
            by: "Tripp, Liberty San Francisco",
            location: "SAN FRANCISCO",
        },
        {
            id: 5,
            text: "Our staff are compassionate and engaging. Our main goal is to find what's best for our customers. Being located in Detroit's Historic Corktown neighborhood provides Liberty Detroit the opportunity to accommodate cannabis consumers in the city and surrounding metro areas.",
            by: "Katelyne, Liberty Detroit",
            location: "DETROIT",
        },
        {
            id: 6,
            text: "Patients in Rockville have a plethora of dispensaries from which to choose. We constantly hear the same heartening accolades when we ask why they prefer us over the competition: \"Your rewards program is the best in the state, your customer service and product knowledge is unparalleled, your selection is incredible, the store's design is clean and modern, and you're always open when I need my medicine.\"",
            by: "Dan, Liberty Rockville",
            location: "ROCKVILLE",
        },
        {
            id: 7,
            text: "We strive to make the customer's visit an experience, rather than just a transaction. Anyone who visits is guaranteed to receive excellent customer service provided by friendly, educated staff. There is a reason we've been operating for over 10+ years!",
            by: "Danielle, Liberty Beach",
            location: "BEACH CENTER",
        },
       /* {
            id: 8,
            text: "At Liberty Van Nuys, we're not just serving cannabis, we're serving the community with love, knowledge, and quality.",
            by: "Tyler, Liberty Van Nuys",
            location: "VAN NUYS",
        }, */
        {
            id: 9,
            text: "At Liberty Aliquippa, our patients feel like family.",
            by: "CORRIE FORMAINI, LIBERTY ALIQUIPPA",
            location: "ALIQUIPPA",
        },
        {
            id: 10,
            text: "We provide the Greater Boston area with the friendliest service and a large variety of cannabis products.",
            by: "Jeremy, Liberty Somerville",
            location: "SOMERVILLE",
        },
        {
            id: 11,
            text: "In the \"City of Firsts\", we are one. Our store is a beautifully designed Adult Use location with the most knowledgeable and friendly staff in the area.",
            by: "Chris, Liberty Springfield",
            location: "SPRINGFIELD",
        },
        {
            id: 12,
            text: "At Liberty in Norristown, our friendly, knowledgeable, and compassionate staff will provide the most exceptional patient experience.",
            by: "Ralph, Liberty Norristown",
            location: "NORRISTOWN",
        },
        {
            id: 13,
            text: "Like a reflection of the valley in which we're located, Liberty Easthampton is an inviting and warm space of greenery, with the friendliest team of knowledgeable Wellness Guides, and the best variety and quality of products in the area.",
            by: "John, Liberty Easthampton",
            location: "EASTHAMPTON",
        },
        {
            id: 14,
            text: "Liberty Van Nuys, not just serving cannabis, but serving the community with love, knowledge, and quality.",
            by: "TYLER, VAN NUYS",
            location: "VAN NUYS",
        },
        {
            id: 15,
            text: "We are thrilled to open our doors and can't wait to meet you!",
            by: "TEAM LIBERTY, CRANBERRY TOWNSHIP",
            location: "CRANBERRY TOWNSHIP",
        },
        {
            id: 16,
            text: "Patients in Rockville have a plethora of dispensaries from which to choose. We constantly hear the same heartening accolades when we ask why they prefer us over the competition: \"Your rewards program is the best in the state, your customer service and product knowledge is unparalleled, your selection is incredible, the store's design is clean and modern, and you're always open when I need my medicine.\"",
            by: "Dan, Liberty Rockville",
            location: "Maggie's - Baltimore",
        },
    ],
}

module.exports = employeeTestimonials;
